import { useState } from "react";
import "../App.scss";
import { Container, Nav , Navbar, Offcanvas, Button} from 'react-bootstrap';


function OffcanvasNav(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
      <Navbar fixed="top" >
      <Container className="justify-content-around">
          <Button href={process.env.PUBLIC_URL + "/Atelier"} variant="light bg-transparent border-0 text-uppercase">l'atelier</Button>
            <div className="vr" />
            <Navbar.Brand href={process.env.PUBLIC_URL + "/"}>
          
            <svg   id="DAMlogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169 56" height='45'>
              <g fill="currentcolor">
              <path fill="currentcolor" d="M55.5,11.66a1.19,1.19,0,0,1,1.2-1.2h10a20.9,20.9,0,0,1,9.46,2,14.36,14.36,0,0,1,6.16,5.73A17.35,17.35,0,0,1,84.44,27q0,8.31-4.83,12.58T66.15,43.84H56.7a1.2,1.2,0,0,1-1.2-1.2ZM66.24,38.82q5.82,0,9-3.12c2.08-2.09,3.12-5,3.12-8.72a12.58,12.58,0,0,0-1.38-6,9.89,9.89,0,0,0-4-4.06,12.2,12.2,0,0,0-6.11-1.46h-4.1a1.2,1.2,0,0,0-1.2,1.2V37.62a1.2,1.2,0,0,0,1.2,1.2Z"/>
              <path d="M99.2,10.46h4.53a1,1,0,0,1,.92.62l12.53,31.4a1,1,0,0,1-.92,1.36h-4a1,1,0,0,1-.93-.63l-2.55-6.43a1,1,0,0,0-.93-.63H95.05a1,1,0,0,0-.93.63l-2.51,6.43a1,1,0,0,1-.93.63H86.62a1,1,0,0,1-.92-1.36l12.58-31.4A1,1,0,0,1,99.2,10.46Zm7.12,19.33-3.95-10.4a1,1,0,0,0-1.86,0l-4,10.4a1,1,0,0,0,.93,1.34h7.92A1,1,0,0,0,106.32,29.79Z"/>
              <path d="M122.36,10.46h4.19a1.06,1.06,0,0,1,1,.61l9.93,21.74a1.06,1.06,0,0,0,1.92,0l9.94-21.74a1,1,0,0,1,1-.61h4.18a1.05,1.05,0,0,1,1.06,1V42.78a1.05,1.05,0,0,1-1.06,1.06h-3.61a1.05,1.05,0,0,1-1.06-1.06V27a1.05,1.05,0,0,0-2-.44l-7.64,16.67a1.05,1.05,0,0,1-1,.62h-1.51a1,1,0,0,1-1-.62l-7.64-16.67a1.05,1.05,0,0,0-2,.44V42.78A1.05,1.05,0,0,1,126,43.84h-3.62a1.05,1.05,0,0,1-1-1.06V11.51A1,1,0,0,1,122.36,10.46Z"/>
              <path d="M20,36.56V17.73a.53.53,0,0,1,.53-.53H36.25a.53.53,0,0,1,.53.53v12.1a.53.53,0,0,1-.53.53H27.47a.54.54,0,0,1-.54-.53V24.31a.54.54,0,0,1,.54-.53h2a.53.53,0,0,1,.53.53v2.23a.54.54,0,0,0,.54.53H33a.54.54,0,0,0,.54-.53v-5.6a.54.54,0,0,0-.54-.53H23.74a.54.54,0,0,0-.54.53v12.2a.54.54,0,0,0,.54.54H44a17,17,0,1,0-2,3.42H20.52A.54.54,0,0,1,20,36.56Z"/>
              </g></svg>
            </Navbar.Brand>
      <div className="vr" />
              <Button onClick={handleShow} variant="light bg-transparent border-0 text-uppercase">
  menu
</Button>
</Container>
<Offcanvas className="dark" show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>MENU</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Nav defaultActiveKey="/" className="text-uppercase flex-column">
      <Nav.Link href={process.env.PUBLIC_URL + "/"}>Accueil</Nav.Link>
      <Nav.Link href={process.env.PUBLIC_URL + "/Bagues"}>Bagues</Nav.Link>
      <Nav.Link href={process.env.PUBLIC_URL + "/Pendentifs"}>Pendentifs</Nav.Link>
      <Nav.Link href={process.env.PUBLIC_URL + "/Boucles"}>Boucles d'oreille</Nav.Link>
      <Nav.Link href={process.env.PUBLIC_URL + "/Atelier"}>L'atelier</Nav.Link>
    </Nav>
        </Offcanvas.Body>
      </Offcanvas>
        </Navbar>
  );
}

export default OffcanvasNav;
