import "../App.scss";
import {Container, Row, Col, Image, Stack}from "react-bootstrap";
import BagueD from "./../media/DAM-bague-D2.png"
import PendentifCarre from "./../media/update-img/DAM-carre.png"
import PendentifRose from "./../media/update-img/DAM-RoseBleue.png"
import PendentifTortue from "./../media/update-img/DAM-Tortue.png"
import Pendentif11 from "./../media/update-img/DAM-Pendentif11.png"
import Pendentif12 from "./../media/update-img/DAM-Pendentif12.png"
import Pendentif13 from "./../media/update-img/DAM-Pendentif13.png"
import Pendentif14 from "./../media/update-img/DAM-Pendentif14.png"
import Pendentif15 from "./../media/update-img/DAM-Pendentif15.png"
import Pendentif2 from "./../media/update-img/DAM-Pendentif2.png"
import Pendentif3 from "./../media/update-img/DAM-Pendentif3.png"
import Pendentif8 from "./../media/update-img/DAM-Pendentif8.png"
import Bague from "./../media/update-img/DAM-Bague.png"
import BagueG from "./../media/DAM-bague-G1.png"
import Loupe1 from "./../media/Loupe1.png"
import Loupe2 from "./../media/Loupe2.png"
import BagueE from "./../media/DAM-bague-E2.png"
import { useEffect } from "react"
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

function Accueil() {
    useEffect(() => {
        AOS.init();
      }, []);
      const ligne = {stroke:"var(--svg-color)"};
      const rect = {fill: "var(--svg-color)"};
  return (
    <>
     <Container className="Sect">
         <Row className="my-2 justify-content-center">
            <Col xs={12} md={10} lg={7}>
            <div className="d-block header" >
             <svg id="svgComponent" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 682 674.68">
                
 <polyline style={ligne} id="ligne" points="621.7 493.68 230.08 493.68 230.08 211.68 461.58 211.68 461.58 379.18 347.08 379.18 347.08 295.65"/>
 <path style={rect} id="rect" d="M0,0V674.68H682V0ZM342.5,630.21c-162.92,0-295-132.08-295-295s132.08-295,295-295,295,132.07,295,295S505.42,630.21,342.5,630.21Z"/></svg>
      </div>
     
            </Col>
            <Col  xs={12} md={2} lg={2} className="d-flex mb-md-5 justify-content-end">
            <div className="pb-5 pt-3  d-flex align-items-end text-uppercase me-3">
            <h4>Des créations<br/>
artisanales<br/>
uniques</h4></div>
            </Col>
           
          </Row>
  
          <Row className="d-flex mb-md-5 justify-content-center">
          <Col  xs={12} md={6} className="d-flex text-center">
            <div className="pb-5 pt-5">
            <h1>Dernières réalisations</h1>
            <p>Les bijoux sont façonnés main selon les techniques et gestes ancestraux d'orfèvrerie, en utilisant des métaux recyclés dans une démarche responsable</p>
            </div>
            </Col>

          </Row>
          </Container>
          <Container fluid className="position-relative">
          <Row className="d-lex mb-5 mx-md-5 justify-content-center">

            <Col xs={11} md={6} lg={4}>
            <Stack direction="horizontal" gap="3">
            <Image height='200' src={BagueD} />
            <div className="legende"> <b>Bague</b><small><br/>Argent</small></div>
            </Stack>
            </Col>

            <Col as={Row} xs={12} md={12} lg={6} className="ms-md-5 mb-3" 
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out">
            <Col xs={11} md={6}>
            <Image  height='400'  src={PendentifRose} /></Col>
            <Col xs={11} md={6} className="ps-5 d-flex align-items-center">
            <div className="legende"> <b>Pendentif</b><br/><small>Argent<br/>Laiton<br/>Turquoise</small></div>
            </Col>
            </Col>


            <Col as={Row} xs={11} md={12} lg={6} className="mt-lg-n5 ms-4"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"s
            data-aos-easing="ease-in-out">
            <Col xs={11} md={6} lg={5}>
            <Image height='450'  src={PendentifCarre}/></Col>
            <Col xs={11} md={6} className="ps-5 d-flex align-items-center">
           <div className="legende"> <b>Pendentif</b><br/><small>Argent<br/>Chrysocolle</small></div>
           </Col>
            </Col>
           
            <Col as={Row} xs={11} md={12} lg={6} className="d-flex justify-content-md-end mt-md-5"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out">
             <Col xs={11} md={7} lg={5}>
            <Image height='440' src={PendentifTortue}/></Col>
            <Col xs={11} md={5} className="ps-5 d-flex align-items-center">
            <div className="legende"> <b>Pendentif</b><br/><small>Argent<br/>Turquoise</small></div>
            </Col>
            </Col>

            <Col as={Row} xs={11} md={12} lg={6} className="d-flex mt-lg-n5 ms-4 justify-content-md-end  align-end">
            <Col xs={11} md={6} lg={4}>
            <Image height='310'  src={Pendentif11}/></Col>
            <Col xs={11} md={6} lg={4} className="ps-5 d-flex align-items-center">
           <div className="legende"> <b>Pendentif</b><br/><small>Argent<br/>Pierre de Lune</small></div>
           </Col>
            </Col>

            <Col as={Row} xs={11} md={12} lg={6} className="d-flex justify-content-md-end mt-md-5"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out">
            <Col xs={11} md={6} lg={5}>
            <Image height='340' src={Pendentif12} /></Col>
            <Col xs={11} md={6} lg={5} className="ps-5 d-flex  align-items-center">
              <div className="legende"> <b>Pendentif</b><br/><small>Argent<br/>Turquoise</small></div>
            </Col>
            </Col>

            <Col as={Row} xs={11} md={12} lg={6} className="d-flex mt-md-n5 ms-4 justify-content-md-end  align-end">
            <Col xs={11} md={6} lg={5}>
            <Image height='400' src={Pendentif13}/></Col>
            <Col xs={11} md={6} lg={5} className="ps-5 d-flex  align-items-center">
           <div className="legende"> <b>Pendentif</b><br/><small>Argent<br/>Laiton<br/>Labradorit</small></div>
           </Col>
            </Col>

            <Col as={Row} xs={12} md={12} lg={6} className=" mb-3" 
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out">
             <Col xs={11} md={6} lg={4}>
            <Image height='400'  src={Pendentif14} /></Col>
            <Col xs={11} md={6} className="ps-5 d-flex align-items-center">
            <div className="legende"> <b>Pendentif</b><br/><small>Argent<br/>Laiton<br/>Jaspe dendritique</small></div>
            </Col>
            </Col>


            <Col as={Row} xs={11} md={12} lg={6} className="mt-md-n5 ms-4"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out">
           <Col xs={11} md={6} lg={4}>
            <Image height='350'  src={Pendentif15}/></Col>
            <Col xs={11} md={6} className="ps-5 d-flex align-items-center">
           <div className="legende"> <b>Pendentif</b><br/><small>Argent<br/>Lapis Lazuli</small></div>
           </Col>
            </Col>

            <Col as={Row} xs={11} md={12} lg={5}  className="d-flex ms-md-4 justify-content-end my-md-5"
             data-aos="zoom-in-left"
             data-aos-duration="1200"
             data-aos-delay="0"
             data-aos-easing="ease-in-out">
            <Col xs={11} md={6} lg={5}>
            <Image height='350' src={Pendentif2}/></Col>
            <Col xs={11} md={6} className="ps-5 d-flex align-items-center">
            <div className="legende"> <b>Pendentif</b><br/><small>Laiton<br/>Coquillage<br/>Lapis Lazuli</small></div>
            </Col>
            </Col>
            
            <Col as={Row} xs={11} md={6} lg={5} className="d-flex  mt-5 justify-content-end">
            <Stack direction="horizontal" gap="2">
            <Image height='180' src={BagueG} />
            <div className="legende"> <b>Bague</b><br/><small>Argent<br/>Jaspe de la Désirade</small></div>
            </Stack>
            </Col>


            <Col as={Row} xs={11} md={6} lg={6} className="d-flex mt-lg-n3">
            <Col xs={11} md={7} lg={4}>
            <Image height='150' src={BagueE} /></Col>
            <Col xs={11} md={5} className="ps-5 d-flex align-items-center">
            <div className="legende"> <b>Bague</b><br/><small>Argent<br/>Malachite<br/>Oeil de Tigre</small></div>
            </Col>
            </Col>

            <Col as={Row} xs={11} md={12} lg={5} className="d-flex justify-content-md-end mt-md-5"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out">
             <Col xs={11} md={6} >
            <Image height='440' src={Pendentif3} /></Col>
            <Col xs={11} md={4} className="ps-5 d-flex align-items-center">
            <div className="legende"> <b>Pendentif</b><br/><small>Laiton</small></div>
            </Col>
            </Col>

            <Col as={Row} xs={11} md={8} lg={6} className="d-flex mt-5 mt-md-n5 ms-4 justify-content-md-end">
           <Stack direction="horizontal" gap="3" className="mx-auto">
            <Image height='210'  src={Bague}/>
           <div className="legende"> <b>Bague</b><br/><small>Laiton</small></div>
           </Stack>
            </Col>

           
            <Col as={Row} xs={11} md={8} lg={5} className="d-flex mt-md-5 justify-content-md-end">
            <Col xs={11} md={6}>
            <Image height='350' src={Pendentif8} /></Col>
            <Col xs={11} md={6} className="ps-5 d-flex align-items-center">
            <div className="legende"> <b>Pendentif</b><br/><small>Argent<br/>Labradorit</small></div>
            </Col>
            </Col>
            

         </Row>

          <div><Image src={Loupe1} className="d-none d-md-block loupe z-3 position-absolute top-0 end-0"/></div>
          <div className="d-none d-md-block"><Image  src={Loupe2} className="loupe z-3 position-absolute start-0 bottom-0"/></div>

        
      </Container>

      </>
  );
}

export default Accueil;
