import React from "react";
import "../App.scss";
import Nav from "./Nav";
import ToggleTheme from "./ToggleTheme";
import Footer from "./Footer";
import { Container, Row, Col, Image, Stack } from "react-bootstrap";
import Boucles from "./../media/Header-Boucles.png";
import BouclesA from "./../media/DAM-Boucles-A.png";
import BouclesB from "./../media/DAM-Boucles-B.png";
import BouclesD from "./../media/DAM-Boucles-D.png";
import BouclesE from "./../media/DAM-Boucles-E.png";
import BouclesF from "./../media/DAM-Boucles-F.png";
import Loupe5 from "./../media/Loupe5.png";
import Loupe2 from "./../media/Loupe2.png";
import { useEffect } from "react";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

function SectBoucles() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="page">
      <ToggleTheme />
      <Nav />
      <Container fluid className="Sect">
        <Row className="my-md-4 px-lg-4 justify-content-center">
          <Image height="350" fluid src={Boucles} />
        </Row>
      </Container>
      <Container fluid className="position-relative">
        <Row className="mb-5 mx-md-5 justify-content-center">
          <Col
            xs={12}
            md="auto"
            lg={5}
            className="mb-5 d-flex justify-content-end"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <Row gap="3" className="d-flex align-items-center">
              <Col xs={12} md={6}>
                <Image height="300" src={BouclesA} />{" "}
              </Col>
              <Col xs={12} md={6}>
                <div className="float-end legende">
                  <small>
                    Argent
                    <br />
                    CHRYSOCOLLE
                  </small>
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            xs={12}
            md="auto"
            lg={5}
            className="ms-md-5 mb-3 d-flex justify-content-md-end"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <Row gap="3" className="d-flex align-items-center">
              <Col xs={12} md={6}>
                <Image height="300" src={BouclesB} />
              </Col>
              <Col xs={12} md={6}>
                <div className="float-md-end legende">
                  <small>
                    Laiton
                    <br />
                    Grenat
                  </small>
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            xs={12}
            lg={5}
            className="ms-md-5 mb-3 d-flex align-items-center justify-content-md-end"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <Row gap="3" className="d-flex align-items-center">
              <Col xs={12} md={8}>
                <Image height="300" src={BouclesD} />
              </Col>
              <Col xs={12} md={4}>
                <div className="float-end legende">
                  <small>
                    Argent
                    <br />
                    Cuivre
                    <br />
                    Laiton
                  </small>
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            xs={12}
            md="auto"
            lg={5}
            className="ms-md-5 mb-3 d-flex "
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <Stack direction="horizontal" gap="3">
              <Image height="300" src={BouclesE} />
              <div className="legende">
                <small>
                  Laiton
                  <br />
                  Lapis-Lazuli
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            lg={5}
            className="ms-md-5 mb-3 d-flex"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <Stack direction="horizontal" gap="3">
              <Image height="300" src={BouclesF} />
              <div className="legende">
                <small>
                  Laiton
                  <br />
                  Chrysocolle
                </small>
              </div>
            </Stack>
          </Col>
        </Row>

        <div>
          <Image
            src={Loupe2}
            className="d-none loupe z-3 position-absolute top-0 start-0"
          />
        </div>
        <div className="d-none d-lg-block ">
          <Image
            src={Loupe5}
            className="loupe z-3 position-absolute end-0 bottom-0"
          />
        </div>
      </Container>
      <Footer />
      </div>
  );
}

export default SectBoucles;
