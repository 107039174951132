import { useEffect, useState} from "react";
import Button from "react-bootstrap/Button";

function Theme() {
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || 'dark'
  );
  const toggleTheme = () => {
    if (theme === 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  };
  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
  }, [theme]);

  return (
    <div className={`App ${theme}`}>
      <Button
        className="rounded-circle btn-switch"
        onClick={toggleTheme}
      ></Button>
    </div>
  );
}

export default Theme;