import "../App.scss";
import { Container, Row, Col, ListGroup, Stack, Image } from "react-bootstrap";
import David from "./../media/David.png";

function Footer() {
  return (
    <>
      <hr />
      <footer>
        <Container className="pt-5">
          <div className="text-center mx-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 169 56"
              height="45"
            >
              <g fill="currentcolor">
                <path
                  fill="currentcolor"
                  d="M55.5,11.66a1.19,1.19,0,0,1,1.2-1.2h10a20.9,20.9,0,0,1,9.46,2,14.36,14.36,0,0,1,6.16,5.73A17.35,17.35,0,0,1,84.44,27q0,8.31-4.83,12.58T66.15,43.84H56.7a1.2,1.2,0,0,1-1.2-1.2ZM66.24,38.82q5.82,0,9-3.12c2.08-2.09,3.12-5,3.12-8.72a12.58,12.58,0,0,0-1.38-6,9.89,9.89,0,0,0-4-4.06,12.2,12.2,0,0,0-6.11-1.46h-4.1a1.2,1.2,0,0,0-1.2,1.2V37.62a1.2,1.2,0,0,0,1.2,1.2Z"
                />
                <path d="M99.2,10.46h4.53a1,1,0,0,1,.92.62l12.53,31.4a1,1,0,0,1-.92,1.36h-4a1,1,0,0,1-.93-.63l-2.55-6.43a1,1,0,0,0-.93-.63H95.05a1,1,0,0,0-.93.63l-2.51,6.43a1,1,0,0,1-.93.63H86.62a1,1,0,0,1-.92-1.36l12.58-31.4A1,1,0,0,1,99.2,10.46Zm7.12,19.33-3.95-10.4a1,1,0,0,0-1.86,0l-4,10.4a1,1,0,0,0,.93,1.34h7.92A1,1,0,0,0,106.32,29.79Z" />
                <path d="M122.36,10.46h4.19a1.06,1.06,0,0,1,1,.61l9.93,21.74a1.06,1.06,0,0,0,1.92,0l9.94-21.74a1,1,0,0,1,1-.61h4.18a1.05,1.05,0,0,1,1.06,1V42.78a1.05,1.05,0,0,1-1.06,1.06h-3.61a1.05,1.05,0,0,1-1.06-1.06V27a1.05,1.05,0,0,0-2-.44l-7.64,16.67a1.05,1.05,0,0,1-1,.62h-1.51a1,1,0,0,1-1-.62l-7.64-16.67a1.05,1.05,0,0,0-2,.44V42.78A1.05,1.05,0,0,1,126,43.84h-3.62a1.05,1.05,0,0,1-1-1.06V11.51A1,1,0,0,1,122.36,10.46Z" />
                <path d="M20,36.56V17.73a.53.53,0,0,1,.53-.53H36.25a.53.53,0,0,1,.53.53v12.1a.53.53,0,0,1-.53.53H27.47a.54.54,0,0,1-.54-.53V24.31a.54.54,0,0,1,.54-.53h2a.53.53,0,0,1,.53.53v2.23a.54.54,0,0,0,.54.53H33a.54.54,0,0,0,.54-.53v-5.6a.54.54,0,0,0-.54-.53H23.74a.54.54,0,0,0-.54.53v12.2a.54.54,0,0,0,.54.54H44a17,17,0,1,0-2,3.42H20.52A.54.54,0,0,1,20,36.56Z" />
              </g>
            </svg>
          </div>

          <Row className="my-3 align-items-center d-flex">
            <Col xs={11} md={8} lg={4} className="mx-auto my-3 text-uppercase">
              Commandez une création sur mesure,
              <br />
              achetez une pièce unique,
              <br />
              créez votre bijou à l’atelier
            </Col>
            <div className="d-block d-lg-none">
              <hr />
            </div>
            <Col md={10} lg={5} className="mx-auto">
              <Row className="justify-content-center d-flex align-items-center">
                <Col sm={6}>
                  <Stack direction="horizontal" gap="3">
                    <Image height="170" src={David} />
                    <div className="text-uppercase">
                      {" "}
                      David
                      <br /> Almuna
                      <br /> Molina{" "}
                    </div>
                  </Stack>
                </Col>
                <Col sm={6}>
                  <Stack direction="horizontal" gap="2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      height="40"
                    >
                      <path
                        fill="currentcolor"
                        d="M15,3.5A11.5,11.5,0,1,0,26.52,15,11.52,11.52,0,0,0,15,3.5Zm5.29,18.08a2.84,2.84,0,0,1-3.81,1.56,11,11,0,0,1-4.07-3.25A14.08,14.08,0,0,1,9.06,13,13.68,13.68,0,0,1,9,9a2.59,2.59,0,0,1,2.48-2.33l.39,0a1.25,1.25,0,0,1,1.42,1c.22,1,.4,2,.6,2.93h-.1a1.43,1.43,0,0,1-.94,1.58c-.93.47-1,.56-.7,1.58a10.17,10.17,0,0,0,2.46,4.37c.42.47.63.48,1.19.17.18-.1.36-.21.55-.3a1.28,1.28,0,0,1,1.33.11,13.51,13.51,0,0,1,2.69,2.65A.76.76,0,0,1,20.32,21.58Z"
                      />
                    </svg>
                    +590 690 26 42 10
                  </Stack>
                </Col>
              </Row>
            </Col>
            <Col xs={11} md={6} lg={3} className="mx-auto my-3 text-uppercase">
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  href={process.env.PUBLIC_URL + "/"}
                  className="bg-transparent"
                >
                  Accueil
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href={process.env.PUBLIC_URL + "/Atelier"}
                  className="bg-transparent"
                >
                  L'atelier
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href={process.env.PUBLIC_URL + "/Bagues"}
                  className="bg-transparent"
                >
                  Bagues
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href={process.env.PUBLIC_URL + "/Pendentifs"}
                  className="bg-transparent"
                >
                  Pendentifs
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href={process.env.PUBLIC_URL + "/Boucles"}
                  className="bg-transparent"
                >
                  Boucles d'oreille
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
        <div>
          <Row className="d-flex justify-content-center mt-4">
            <Col xs={12} md={8} lg={6} className="mx-auto d-block">
              <p className="text-muted text-center">
                ©2023 ATELIER<b>DAM</b>
              </p>
            </Col>
          </Row>
        </div>
      </footer>
    </>
  );
}

export default Footer;
