import React from "react";
import "../App.scss";
import Nav from './Nav'
import ToggleTheme from './ToggleTheme'
import Footer from './Footer'
import {Container, Row, Col, Image} from "react-bootstrap";
import Atelier from "./../media/DAM-Atelier.png"
import David from "./../media/DAM-David.png"
import Atelier2 from "./../media/DAM-Atelier2.png"
import Atelier3 from "./../media/DAM-Atelier3.png"
import { useEffect } from "react"
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

function AtelierDAM() {
  useEffect(() => {
      AOS.init();
    }, []);
return (
  <div className="page">
  <ToggleTheme />
  <Nav/>
     <Container className="Sect">
         <Row className="mb-5">
            <Col xs={12} md={8} lg={7}>
            <Image fluid src={Atelier} />
            <div>
                <h1 className="text-uppercase">L'atelier</h1>
                Chacun de mes bijoux est le résultat d'un processus artisanal de création attentif et méticuleux.
                <br/><b>Toutes les pièces sont ciselées à la main.</b> J'intègre les éléments de l'art de la joaillerie et de l’horlogerie de la conception jusqu'au sertissage de pierres précieuses. 
               <h4 className="mt-3">Matières premières </h4>
                J'utilise des matériaux et métaux recyclés comme le cuivre, le laiton, le bronze, l'argent (par exemple des services de table).<br/>
                Ainsi des façonnages autour de belles pierres semi-précieuses remodèlent horloges, vieilles montres, pendules et autres matières premières initialement destinées aux déchets.
                <h4 className="mt-3">Design </h4>
                Beaucoup de mes créations évoquent l'état d'esprit du moment. Généralement, je capture l'idée dans un croquis ou me laisse simplement guider par la beauté de la pierre. </div>


            <Image data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out" height="250px" src={Atelier2} className="ml-n5 float-left wrap-left"/> 
<div className="float-right"> <h4 className="mt-3">Façonnage</h4>
Chaque bijou est unique et, pour sa fabrication, j'utilise le façonnage manuel du métal, technique artisanale avec laquelle les bijoux sont fabriqués depuis l'Antiquité. Par un processus laborieux, nécessitant de nombreuses heures et de l'expérience, il s'agit d'obtenir la forme et l'aspect souhaités de la pièce par la manipulation, la chaleur et les outils manuels. Le façonnage englobe un ensemble de techniques et d'opérations telles que le moulage, l'étirage, le laminage, le formage, l'usinage et la soudure, entre autres. 
</div>
<Image data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out" height="250px" src={Atelier3} className="ml-n5 float-right wrap-right"/> 
<div className="float-left"> 
<h4 className="mt-3">Sertissage des pierres </h4>
Le sertissage traditionnel consiste à poser, ajuster et maintenir les pierres précieuses fixées sur le bijou. Il existe différentes façons de procéder, mais parmi les principales, on peut citer le sertissage à griffes, le sertissage à rail, le sertissage pavé, le sertissage à picots et le sertissage invisible. </div>  
<div>
C'est l'ensemble de ce processus qui rend le bijou unique et le différencie des autres.
 </div>
            </Col>
            <Col  xs={12} md={4} lg={5} className="mb-md-5 justify-content-end">
            <div className="pb-5 d-flex align-items-end">
            <Image  fluid className="p-sm-5 p-md-1 p-lg-4" src={David} /></div>
            
            <div className="legendeD">
            <p><b>David, né au Chili, se forme directement auprès d’orfèvres.</b> L’orfèvrerie est un artisanat traditionnel du patrimoine chilien qui s’est développé grâce au savoir-faire des artisans mapuches.</p> 
            <p>Résidant en France depuis 2001, David suit une formation d’horlogerie puis travaille chez des horlogers à la réparation et réalisation de pièces pour montres, carillons, pendules.</p> 
<p>Il retourne à la création de bijoux en y recyclant des mécanismes d’horlogerie.</p> 
<p>Il participe à la création du collectif ZoneArt de Besançon, réalisant des partenariats artistiques pour le partage des savoirs-faire </p> 
Installé en Guadeloupe, il poursuit ses créations et souhaite transmettre son métier d'orfèvre.</div>
<div className="mt-5 d-flex float-end align-items-end">
<div data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out" className="px-sm-5 px-md-2 px-lg-5 float-end mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384.55 235.96" height="300" className="img-fluid">
            <g fill="currentcolor">
                <path d="M79,69.63l-7.35-16.8H75L81.55,68h-2l6.67-15.2h3.1L82,69.63Z"/>
            <path d="M94.25,67h9.48v2.64H91.13V52.83h12.26v2.64H94.25ZM94,59.82h8.35v2.57H94Z"/>
            <path d="M107.21,69.63V52.83h2.56l10.54,13H119v-13h3.07v16.8h-2.57L109,56.67h1.27v13Z"/>
            <path d="M129.77,67h9.48v2.64h-12.6V52.83h12.26v2.64h-9.14Zm-.24-7.17h8.35v2.57h-8.35Z"/>
            <path d="M141.45,69.63V67.55L152.3,54.3l.39,1.17H141.62V52.83h13.85v2.09L144.62,68.17,144.26,67h11.55v2.64Z"/>
            <path d="M45.05,94.63H41.93V77.83H54.19v2.64H45.05Zm-.24-9h8.35v2.59H44.81Z"/>
            <path d="M54.12,94.63l7.56-16.8h3.07l7.59,16.8H69.05L62.57,79.49h1.25L57.31,94.63Zm3.48-3.88.86-2.45h9.05l.84,2.45Z"/>
            <path d="M74.47,94.63V77.83h7.9A7.53,7.53,0,0,1,87,79a3.77,3.77,0,0,1,1.59,3.18,4,4,0,0,1-.63,2.29A4.11,4.11,0,0,1,86.24,86a5.06,5.06,0,0,1-2.26.51l.43-.86a6,6,0,0,1,2.54.52,4.09,4.09,0,0,1,1.78,1.5,4.35,4.35,0,0,1,.65,2.44,3.92,3.92,0,0,1-1.65,3.36,8.22,8.22,0,0,1-4.88,1.2Zm3.12-2.44h5.07a5.12,5.12,0,0,0,2.66-.58,2.27,2.27,0,0,0,0-3.68,4.93,4.93,0,0,0-2.66-.59H77.35V84.91H82a4.59,4.59,0,0,0,2.49-.57,2.15,2.15,0,0,0,0-3.48A4.5,4.5,0,0,0,82,80.28H77.59Z"/>
            <path d="M92.74,94.63V77.83h6.91a9.45,9.45,0,0,1,3.83.71,5.54,5.54,0,0,1,2.46,2,5.89,5.89,0,0,1,.86,3.22,5.71,5.71,0,0,1-.86,3.17,5.65,5.65,0,0,1-2.46,2,9.28,9.28,0,0,1-3.83.72H94.49l1.37-1.39v6.28Zm3.12-5.95-1.37-1.51h5a4.69,4.69,0,0,0,3.11-.89,3,3,0,0,0,1-2.47,3,3,0,0,0-1-2.46,4.75,4.75,0,0,0-3.11-.88h-5l1.37-1.53Zm7.87,5.95-4.25-6.09h3.34l4.27,6.09Z"/>
            <path d="M110.28,94.63V77.83h3.12v16.8Z"/>
            <path d="M125.81,94.87a10,10,0,0,1-3.62-.64,8.73,8.73,0,0,1-2.89-1.83,8.19,8.19,0,0,1-1.89-2.75,9,9,0,0,1,0-6.85,8.21,8.21,0,0,1,1.89-2.73,8.63,8.63,0,0,1,2.88-1.83,9.93,9.93,0,0,1,3.63-.65,10.09,10.09,0,0,1,3.6.64A8.3,8.3,0,0,1,132.27,80a8.44,8.44,0,0,1,1.89,2.75,9.19,9.19,0,0,1,0,6.89,8.37,8.37,0,0,1-4.75,4.56A10.09,10.09,0,0,1,125.81,94.87Zm0-2.73a6.4,6.4,0,0,0,2.34-.43A5.61,5.61,0,0,0,130,90.47a5.7,5.7,0,0,0,1.24-1.88,6.47,6.47,0,0,0,.43-2.36,6.4,6.4,0,0,0-.43-2.36,5.65,5.65,0,0,0-3.11-3.11,6.2,6.2,0,0,0-2.34-.43,6.36,6.36,0,0,0-2.37.43A5.45,5.45,0,0,0,121.56,82a5.84,5.84,0,0,0-1.25,1.87,6,6,0,0,0-.46,2.36,6.09,6.09,0,0,0,.46,2.36,5.89,5.89,0,0,0,1.25,1.88,5.45,5.45,0,0,0,1.88,1.24A6.57,6.57,0,0,0,125.81,92.14Zm5.49,6.19a6.38,6.38,0,0,1-1.72-.22,6.67,6.67,0,0,1-1.64-.67,9.81,9.81,0,0,1-1.68-1.22c-.57-.51-1.21-1.14-1.89-1.87l3.31-.84A9.14,9.14,0,0,0,129,94.92a4.55,4.55,0,0,0,1.2.77,3.06,3.06,0,0,0,1.17.24,3.77,3.77,0,0,0,2.9-1.37l1.42,1.75A5.36,5.36,0,0,1,131.3,98.33Z"/>
            <path d="M145.34,94.87A7.26,7.26,0,0,1,140,92.93a7.6,7.6,0,0,1-2-5.64V77.83h3.12v9.36A5.36,5.36,0,0,0,142.23,91a4.81,4.81,0,0,0,6.31,0,5.41,5.41,0,0,0,1.1-3.77V77.83h3.07v9.46a7.64,7.64,0,0,1-2,5.64A7.34,7.34,0,0,1,145.34,94.87Z"/>
            <path d="M160.2,92h9.48v2.64h-12.6V77.83h12.26v2.64H160.2ZM160,84.82h8.35v2.57H160Z"/>
            <path d="M173.16,94.63V77.83h6.91a9.45,9.45,0,0,1,3.83.71,5.54,5.54,0,0,1,2.46,2,5.89,5.89,0,0,1,.86,3.22,5.71,5.71,0,0,1-.86,3.17,5.65,5.65,0,0,1-2.46,2,9.28,9.28,0,0,1-3.83.72h-5.16l1.37-1.39v6.28Zm3.12-5.95-1.37-1.51h5a4.68,4.68,0,0,0,3.1-.89,3,3,0,0,0,1.05-2.47A3,3,0,0,0,183,81.35a4.74,4.74,0,0,0-3.1-.88h-5l1.37-1.53Zm7.87,5.95-4.25-6.09h3.34l4.27,6.09Z"/>
            <path d="M36.61,119.63l-7.34-16.8h3.38L39.2,118h-2l6.67-15.2H47l-7.32,16.8Z"/>
            <path d="M56.48,119.87a9.93,9.93,0,0,1-3.61-.64A8.73,8.73,0,0,1,50,117.4a8.22,8.22,0,0,1-1.9-2.75,8.69,8.69,0,0,1-.67-3.42,8.83,8.83,0,0,1,.67-3.44,8.18,8.18,0,0,1,1.9-2.74,8.91,8.91,0,0,1,2.88-1.81,9.91,9.91,0,0,1,3.62-.65,10,10,0,0,1,3.6.64A8.32,8.32,0,0,1,63,105a8.24,8.24,0,0,1,1.89,2.75,9.22,9.22,0,0,1,0,6.9,8.35,8.35,0,0,1-4.76,4.56A10,10,0,0,1,56.48,119.87Zm0-2.73a6.33,6.33,0,0,0,2.34-.43,5.4,5.4,0,0,0,1.86-1.23,5.71,5.71,0,0,0,1.24-1.87,6.11,6.11,0,0,0,.44-2.38,6.29,6.29,0,0,0-.43-2.37A5.44,5.44,0,0,0,60.7,107a5.68,5.68,0,0,0-1.88-1.23,6.13,6.13,0,0,0-2.34-.43,6.34,6.34,0,0,0-2.36.43A5.27,5.27,0,0,0,52.24,107a5.9,5.9,0,0,0-1.71,4.23,6,6,0,0,0,1.71,4.24,5.27,5.27,0,0,0,1.88,1.24A6.55,6.55,0,0,0,56.48,117.14Z"/>
            <path d="M72,119.63V105.47H66.42v-2.64H80.68v2.64H75.11v14.16Z"/>
            <path d="M83,119.63v-16.8h6.91a9.45,9.45,0,0,1,3.83.71,5.54,5.54,0,0,1,2.46,2,5.89,5.89,0,0,1,.86,3.22,5.71,5.71,0,0,1-.86,3.17,5.65,5.65,0,0,1-2.46,2,9.28,9.28,0,0,1-3.83.72H84.78l1.37-1.39v6.28Zm3.12-5.95-1.37-1.51h5a4.68,4.68,0,0,0,3.1-.89,3,3,0,0,0,1-2.47,3,3,0,0,0-1-2.46,4.74,4.74,0,0,0-3.1-.88h-5l1.37-1.53ZM94,119.63l-4.25-6.09h3.34l4.27,6.09Z"/>
            <path d="M103.69,117h9.48v2.64h-12.6v-16.8h12.27v2.64h-9.15Zm-.24-7.17h8.35v2.57h-8.35Z"/><path d="M123.28,119.63v-16.8h7.89a7.59,7.59,0,0,1,4.59,1.19,3.78,3.78,0,0,1,1.58,3.18,4.1,4.1,0,0,1-.62,2.29,4.24,4.24,0,0,1-1.67,1.47,5.11,5.11,0,0,1-2.27.51l.43-.86a6,6,0,0,1,2.55.52,4.06,4.06,0,0,1,1.77,1.5,4.35,4.35,0,0,1,.65,2.44,3.93,3.93,0,0,1-1.64,3.36,8.28,8.28,0,0,1-4.89,1.2Zm3.12-2.44h5.06a5.12,5.12,0,0,0,2.66-.58,2.27,2.27,0,0,0,0-3.68,4.93,4.93,0,0,0-2.66-.59h-5.3v-2.43h4.65a4.63,4.63,0,0,0,2.5-.57,2.15,2.15,0,0,0,0-3.48,4.53,4.53,0,0,0-2.5-.58H126.4Z"/><path d="M141.54,119.63v-16.8h3.12v16.8Z"/><path d="M151.79,119.87a6.86,6.86,0,0,1-2.94-.62,5.49,5.49,0,0,1-2.22-1.82l1.77-2.12a5,5,0,0,0,1.53,1.44,3.57,3.57,0,0,0,1.83.48c1.73,0,2.59-1,2.59-3.09v-8.67h-6v-2.64h9.07V114a6.11,6.11,0,0,1-1.44,4.44A5.65,5.65,0,0,1,151.79,119.87Z"/>
            <path d="M169.71,119.87a9.93,9.93,0,0,1-3.61-.64,8.73,8.73,0,0,1-2.89-1.83,8.22,8.22,0,0,1-1.9-2.75,8.69,8.69,0,0,1-.67-3.42,8.83,8.83,0,0,1,.67-3.44,8.18,8.18,0,0,1,1.9-2.74,8.91,8.91,0,0,1,2.88-1.81,9.87,9.87,0,0,1,3.62-.65,10,10,0,0,1,3.6.64,8.32,8.32,0,0,1,2.87,1.8,8.24,8.24,0,0,1,1.89,2.75,9.22,9.22,0,0,1,0,6.9,8.35,8.35,0,0,1-4.76,4.56A10,10,0,0,1,169.71,119.87Zm0-2.73a6.33,6.33,0,0,0,2.34-.43,5.4,5.4,0,0,0,1.86-1.23,5.71,5.71,0,0,0,1.24-1.87,6.11,6.11,0,0,0,.44-2.38,6.29,6.29,0,0,0-.43-2.37,5.44,5.44,0,0,0-1.23-1.87,5.68,5.68,0,0,0-1.88-1.23,6.13,6.13,0,0,0-2.34-.43,6.34,6.34,0,0,0-2.36.43,5.27,5.27,0,0,0-1.88,1.24,5.9,5.9,0,0,0-1.71,4.23,6,6,0,0,0,1.71,4.24,5.27,5.27,0,0,0,1.88,1.24A6.55,6.55,0,0,0,169.71,117.14Z"/>
            <path d="M189.25,119.87a7.3,7.3,0,0,1-5.39-1.94,7.64,7.64,0,0,1-2-5.64v-9.46H185v9.36a5.36,5.36,0,0,0,1.11,3.77,4.14,4.14,0,0,0,3.16,1.18,4.07,4.07,0,0,0,3.14-1.18,5.36,5.36,0,0,0,1.11-3.77v-9.36h3.07v9.46a7.6,7.6,0,0,1-2,5.64A7.32,7.32,0,0,1,189.25,119.87Z"/>
            <path d="M40.85,144.63l7.56-16.8h3.07l7.58,16.8H55.77L49.3,129.49h1.24L44,144.63Zm3.48-3.88.86-2.45h9.05l.84,2.45Zm5.06-14.28-4.2-3.29h3.55L52,126.47Z"/><path d="M67.82,144.63v-16.8h3.12V142h8.79v2.64Z"/>
            <path d="M80,134.1l1.37-5.33.45,1.8a2,2,0,0,1-1.42-.53,1.82,1.82,0,0,1-.57-1.41,1.87,1.87,0,0,1,.57-1.43,1.9,1.9,0,0,1,1.38-.54,1.87,1.87,0,0,1,1.4.55,2,2,0,0,1,.54,1.42,3.44,3.44,0,0,1,0,.54,2.91,2.91,0,0,1-.15.62c-.08.23-.19.53-.34.9l-1.27,3.41Z"/>
            <path d="M83.71,144.63l7.56-16.8h3.07l7.59,16.8H98.64l-6.48-15.14h1.25L86.9,144.63Zm3.48-3.88.86-2.45h9l.84,2.45Z"/><path d="M106.63,144.63V130.47h-5.57v-2.64h14.26v2.64h-5.57v14.16Z"/><path d="M120.79,142h9.48v2.64h-12.6v-16.8h12.26v2.64h-9.14Zm-.24-7.17h8.35v2.57h-8.35Z"/><path d="M133.75,144.63v-16.8h3.12V142h8.78v2.64Z"/>
            <path d="M148.13,144.63v-16.8h3.12v16.8Z"/><path d="M158.9,142h9.48v2.64h-12.6v-16.8H168v2.64H158.9Zm-.24-7.17H167v2.57h-8.35Z"/><path d="M171.86,144.63v-16.8h6.91a9.42,9.42,0,0,1,3.83.71,5.54,5.54,0,0,1,2.46,2.05,5.8,5.8,0,0,1,.86,3.22A5.36,5.36,0,0,1,182.6,139a9.25,9.25,0,0,1-3.83.72h-5.16l1.37-1.39v6.28Zm3.12-5.95-1.37-1.51h5a4.73,4.73,0,0,0,3.11-.89,3.07,3.07,0,0,0,1-2.47,3,3,0,0,0-1-2.46,4.78,4.78,0,0,0-3.11-.88h-5l1.37-1.53Zm7.87,5.95-4.25-6.09h3.34l4.27,6.09Z"/>
            </g>
            <path fill="#d9d2c8" d="M365,174.49c-6.53-3-13.42-2.8-19.84-1.88-3.67-1-24.18-8.88-45.59-20.56-28.19-17.6-71.42-77.28-98.68-100.39C173.57,27.58,141.83,2.17,96.8,0,68.67-3.52,19.57,23.43,17.17,44.49c-.16,1,6.72-10.38,16.06-18.29a84.08,84.08,0,0,1,7.54-5.68C105.15-17.31,165,30.83,204,72.52c-1.36-2.59,63.77,76.68,64.17,75.47,21.11,23.07,28.77,22.54,44.4,37.31a26.23,26.23,0,0,1-14.75.39c-29.57-7.8-42-4.32-92.19-1.55-75.28,7.28-178.13,11.58-201.39-77.32a84.43,84.43,0,0,1-1.17-9.36C2.25,85.25,5.62,72.39,5,73.19-8.26,90,6.13,143,28.54,161.32c32.86,30.8,73.28,35.93,109.55,38.76,35.54,3.48,108.55-7.05,140.77.93,25.79,9.55,34.73,10.72,40.15,21.87.7,1.18,8,3.08,11,3.83C367.45,260.43,410.44,196.24,365,174.49Zm-1,34.34c-6.44,15-29.33,5.34-23-9.75S370.35,193.75,364.05,208.83Z"/></svg>
            </div></div>
            <div className="legendeD">
<p className="ps-lg-5"><b> L’atelier se situe à Saint-François - Guadeloupe</b>
<br/>
Vous pouvez y venir pour fabriquer votre bijou.
Cours, conseils, outils et matériaux sont diponibles pour vos idées et envies.
</p></div>
            </Col>
           
          </Row>
          </Container>
      <Footer/>
      </div>
  );
}

export default AtelierDAM;
