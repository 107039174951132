import React from "react";
import "../App.scss";
import Nav from "./Nav";
import ToggleTheme from "./ToggleTheme";
import Footer from "./Footer";
import { Container, Row, Col, Image, Stack } from "react-bootstrap";
import PendentifA from "./../media/DAM-Pendentif-A1.png";
import PendentifB from "./../media/DAM-Pendentif-B1.png";
import PendentifN from "./../media/DAM-Pendentif-N.png";
import PendentifD from "./../media/DAM-Pendentif-D1.png";
import PendentifE from "./../media/DAM-Pendentif-E1.png";
import PendentifF from "./../media/DAM-Pendentif-F1.png";
import PendentifH from "./../media/DAM-Pendentif-H.png";
import PendentifI from "./../media/DAM-Pendentif-I.png";
import PendentifJ from "./../media/DAM-Pendentif-J.png";
import PendentifK from "./../media/DAM-Pendentif-K.png";
import PendentifL from "./../media/DAM-Pendentif-L.png";
import Loupe4 from "./../media/Loupe4.png";
import Loupe3 from "./../media/Loupe3.png";
import Pendentifs from "./../media/Header-Pendentifs.png";
import { useEffect } from "react";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

function SectPendentifs() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="page">
      <ToggleTheme />
      <Nav />
      <Container fluid className="Sect">
        <Row className="my-4 px-lg-4 justify-content-center">
          <Image height="350" fluid src={Pendentifs} />
        </Row>
      </Container>
      <Container fluid className="position-relative">
        <Row className="mb-5 mx-md-5 justify-content-end">
          <Col
            xs={12}
            md={6}
            lg={5}
            className="d-flex justify-content-end"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <Stack direction="horizontal" gap="3">
              <Image  height="450" src={PendentifA} />
              <div className="legende">
                {" "}
                <small>
                  Argent
                  <br />
                  Laiton
                  <br />
                  Turquoise
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            md="auto"
            lg={5}
            className="ms-md-5 mb-3 d-flex justify-content-lg-end"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <Stack direction="horizontal" gap="3">
              <Image height="350" src={PendentifB} />
              <div className="legende">
                {" "}
                <small>
                  Argent
                  <br />
                  Laiton
                  <br />
                  Chrysocolle
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            md="auto"
            lg={5}
            className="mt-5 d-flex justify-content-md-start justify-content-center"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <div class="float-end">
              <Stack direction="horizontal" gap="3">
                <Image height="350" src={PendentifF} />
                <div className="legende">
                  {" "}
                  <small>
                    Argent
                    <br />
                    Oeil de Tigre
                    <br />
                  </small>
                </div>
              </Stack>
            </div>
          </Col>

          <Col
            xs={12}
            lg={5}
            md="auto"
            className="d-flex ms-md-4 float-end mt-md-5"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <div class="float-end">
              <Stack direction="horizontal" gap="2">
                <Image height="340" src={PendentifD} />
                <div className="legende">
                  {" "}
                  <small>
                    Argent
                    <br />
                    Laiton
                    <br />
                    Jade
                    <br />
                  </small>
                </div>
              </Stack>
            </div>
          </Col>

          <Col
            xs={12}
            md={6}
            lg={5}
            className="d-flex mt-md-n5 justify-content-end"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="350" src={PendentifE} />
              <div className="legende">
                {" "}
                <small>
                  Argent
                  <br />
                  Laiton
                  <br />
                  Turquoise
                  <br />
                  et Malachite
                  <br />
                  reconstituées
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            md={6}
            lg={5}
            className="d-flex justify-content-md-end mt-md-5"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="350" src={PendentifN} />
              <div className="legende">
                {" "}
                <small>
                  Argent
                  <br />
                  Laiton
                  <br />
                  Lapis-Lazuli
                  <br />
                  Malachite
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            md={6}
            lg={5}
            className="d-flex justify-content-lg-start justify-content-center mt-md-5"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="350" src={PendentifH} />
              <div className="legende">
                {" "}
                <small>
                  Argent
                  <br />
                  Laiton
                  <br />
                  Chrysocolle
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            md={6}
            lg={5}
            className="d-flex mt-md-n3 justify-content-start"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="300" src={PendentifI} />
              <div className="legende">
                {" "}
                <small>
                  Argent
                  <br />
                  Turquoise
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            md={6}
            lg={5}
            className="d-flex justify-content-center mt-md-5"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="350" src={PendentifJ} />
              <div className="legende">
                {" "}
                <small>
                  Argent
                  <br />
                  Agathe
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            md="auto"
            lg={5}
            className="d-flex justify-content-md-end mt-md-5"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Row gap="2" className="d-flex align-items-center">
              <Col xs={12} md={6}>
                <Image height="350" src={PendentifK} />
              </Col>
              <Col xs={12} md={6}>
                <div className="float-end legende">
                  <small>
                    Laiton
                    <br />
                    Pièce d'horlogerie
                  </small>
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            xs={12}
            md="auto"
            lg={5}
            className="mx-auto d-flex justify-content-lg-start mt-md-5"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="350" src={PendentifL} />
              <div className="legende">
                {" "}
                <small>
                  Laiton
                  <br />
                  Grenat
                </small>
              </div>
            </Stack>
          </Col>
        </Row>

        <div>
          <Image
            src={Loupe3}
            className="d-none d-lg-block loupe z-3 position-absolute top-0 start-0"
          />
        </div>
        <div className="d-none d-lg-block">
          <Image
            src={Loupe4}
            className="loupe z-3 position-absolute end-0 bottom-0"
          />
        </div>
      </Container>
      <Footer />
      </div>
  );
}

export default SectPendentifs;
