import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Bagues from "./components/Bagues";
import Boucles from "./components/Boucles";
import Bracelets from "./components/Bracelets";
import Pendentifs from "./components/Pendentifs";
import Atelier from "./components/Atelier";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/Bagues" element={<Bagues />}></Route>
        <Route path="/Boucles" element={<Boucles />}></Route>
        <Route path="/Bracelets" element={<Bracelets />}></Route>
        <Route path="/Pendentifs" element={<Pendentifs />}></Route>
        <Route path="/Atelier" element={<Atelier />}></Route>
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
