import React from "react";
import "../App.scss";
import Nav from "./Nav";
import ToggleTheme from "./ToggleTheme";
import Footer from "./Footer";
import {
  Container,
  Row,
  Col,
  Image,
  Stack,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import BagueO from "./../media/DAM-bague-O1.png";
import BagueO1 from "./../media/DAM-bague-O2.png";
import BagueG from "./../media/DAM-bague-G4.png";
import Loupe1 from "./../media/Loupe1.png";
import Loupe2 from "./../media/Loupe2.png";
import BagueC from "./../media/DAM-bague-C6.png";
import BagueE from "./../media/DAM-bague-E1.png";
import BagueB from "./../media/DAM-bague-B1.png";
import BagueB1 from "./../media/DAM-bague-B3.png";
import BagueD from "./../media/DAM-bague-D2.png";
import BagueD1 from "./../media/DAM-bague-D4.png";
import BagueH from "./../media/DAM-bague-H3.png";
import BagueJ from "./../media/DAM-bague-J2.png";
import BagueA1 from "./../media/DAM-bague-A6.png";
import BagueL from "./../media/DAM-bague-L1.png";
import BagueP from "./../media/DAM-bague-P2.png";
import BagueP1 from "./../media/DAM-bague-P.png";
import Bagues from "./../media/Header-Bagues.png";
import { useEffect } from "react";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

function SectBagues() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="page">
      <ToggleTheme />
      <Nav />
      <Container fluid className="Sect">
        <Row className="my-4 px-lg-4 justify-content-center">
          <Image height="350" fluid src={Bagues} />
        </Row>
      </Container>
      <Container fluid className="position-relative">
        <Row className="mb-5 mx-md-5 justify-content-center">
          <Col
            xs={12}
            md="auto"
            lg={6}
            className="d-flex justify-content-end"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <Stack direction="horizontal" gap="3">
              <div>
                <OverlayTrigger
                  trigger="hover"
                  overlay={
                    <Popover>
                      <Popover.Body bg="light">
                        <Image height="250" src={BagueO} />
                      </Popover.Body>
                    </Popover>
                  }
                >
                 <Image height="250" src={BagueO1} />
                </OverlayTrigger>
              </div>
              <div className="legende">
                <small>
                  Argent
                  <br />
                  Jaspe
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            lg={5}
            className="d-flex float-end ms-md-5 mb-3 justify-content-md-end"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <Stack direction="horizontal" gap="3">
            <div>
                <OverlayTrigger
                  trigger="hover"
                  overlay={
                    <Popover>
                      <Popover.Body bg="light">
                        <Image height="250" src={BagueB1} />
                      </Popover.Body>
                    </Popover>
                  }
                >
              <Image height="250" src={BagueB} />
              </OverlayTrigger>
              </div>
              <div className="legende">
                <small>
                  Argent
                  <br />
                  Amazonite
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            md="auto"
            lg={5}
            className="mt-5 d-flex justify-content-md-start"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <div class="float-end">
              <Stack direction="horizontal" gap="3">
                <div>
                  <OverlayTrigger
                    trigger="hover"
                    overlay={
                      <Popover>
                        <Popover.Body bg="light">
                          <Image height="150" src={BagueD1} />
                        </Popover.Body>
                      </Popover>
                    }
                  >
                   <Image height="150" src={BagueD} />
                  </OverlayTrigger>
                </div>
                <div className="legende">
                  <small>Argent</small>
                </div>
              </Stack>
            </div>
          </Col>

          <Col
            xs={12}
            lg={5}
            className="d-flex ms-md-4 float-end mt-md-5"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
          >
            <div class="float-end">
              <Stack direction="horizontal" gap="2">
                <Image height="180" src={BagueJ} />
                <div className="legende">
                  <small>
                    Argent
                    <br />
                    Laiton
                    <br />
                    Oeil de Tigre
                  </small>
                </div>
              </Stack>
            </div>
          </Col>

          <Col
            xs={12}
            md="auto"
            lg={5}
            className="d-flex mt-lg-n5 justify-content-end"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="250" src={BagueG} />
              <div className="legende">
                <small>
                  Argent
                  <br />
                  Jaspe de la Désirade
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            lg={5}
            className="d-flex justify-content-md-end mt-md-5"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="240" src={BagueH} />
              <div className="legende">
                <small>
                  Argent
                  <br />
                  CHRYSOCOLLE
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            md="auto"
            lg={5}
            className="d-flex mt-md-5"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="220" src={BagueC} />
              <div className="legende">
                <small>
                  Argent
                  <br />
                  Larimar
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            g={5}
            className="d-flex mt-lg-n3 justify-content-start"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="170" src={BagueE} />
              <div className="legende">
                <small>
                  Argent
                  <br />
                  Malachite
                  <br />
                  Oeil de Tigre
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            md="auto"
            lg={5}
            className="d-flex  justify-content-md-end mt-md-5"
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="250" src={BagueA1} />
              <div className="legende">
                <small>
                  Argent
                  <br />
                  Améthyste
                  <br />
                  Jaspe de la Désirade
                </small>
              </div>
            </Stack>
          </Col>

          <Col
            xs={12}
            lg={5}
            className="d-flex justify-content-md-end mt-md-5"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
              <Image height="240" src={BagueL} />
              <div className="legende">
                <small>
                  Laiton
                  <br />
                  Lapis-Lazuli
                </small>
              </div>
            </Stack>
          </Col>
          <Col
            xs={12}
            md="auto"
            lg={5}
            className="d-flex justify-content-md-end mt-md-5"
            data-aos="zoom-in-left"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-out"
          >
            <Stack direction="horizontal" gap="2">
            <div>
                <OverlayTrigger
                  trigger="hover"
                  overlay={
                    <Popover>
                      <Popover.Body bg="light">
                        <Image height="240" src={BagueP} />
                      </Popover.Body>
                    </Popover>
                  }
                >
              <Image height="240" src={BagueP1} />
              </OverlayTrigger>
                </div>
              <div className="legende">
                <small>
                  Argent
                  <br />
                  Jaspe
                </small>
              </div>
            </Stack>
          </Col>
        </Row>

        <div>
          <Image
            src={Loupe2}
            className="d-none loupe z-3 position-absolute top-0 start-0"
          />
        </div>
        <div className="d-none ">
          <Image
            src={Loupe1}
            className="loupe z-3 position-absolute end-0 bottom-0"
          />
        </div>
      </Container>
      <Footer />
      </div>
  );
}

export default SectBagues;
